import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/ua',
  component: Layout,
  // redirect: '/user/list',
  name: 'AppUA',
  meta: {
    title: 'UA',
    icon: 'mingcute:user-info-line',
  },
  children: [
    {
      path: '/app/ua/user/query',
      name: 'AppUaUserQuery',
      component: () => import('@/views/app/ua/user.query.vue'),
      meta: {
        title: '用户查询',
        icon: 'lucide:user-search',
      },
    },
  ],
}

export default routes
