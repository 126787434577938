import type { RouteRecordRaw } from 'vue-router'

const Layout = () => import('@/layouts/index.vue')

const routes: RouteRecordRaw = {
  path: '/statistics/iot',
  component: Layout,
  name: 'StatisticsIot',
  meta: {
    title: '硬件统计',
    icon: 'ion:hardware-chip',
  },
  children: [
    {
      path: 'overview',
      name: 'StatisticsIotOverview',
      component: () => import('@/views/statistics/iot/iot.overview.vue'),
      meta: {
        title: '总览',
        sidebar: true,
        breadcrumb: true,
      },
    },
    {
      path: 'models/List',
      name: 'StatisticsIotModelsList',
      component: () => import('@/views/statistics/iot/models.list.vue'),
      meta: {
        title: '设备型号统计列表',
        sidebar: true,
        breadcrumb: true,
      },
    },
  ],
}

export default routes
